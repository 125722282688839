:root {
  --trading-popover-background: #000000;
  --trading-popover-border: #3e4d47;
  --trading-popover-border-top: #526e63;
}

.toolTip {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      border-radius: 16px !important;
      background: var(--trading-popover-background);
      border: 1px solid var(--trading-popover-border);
      box-shadow: 0 0 10px 10px rgba(59, 59, 59, 0.05);
    }
    :global(.ant-popover-arrow) {
      display: none;
    }
    :global(.ant-popover-inner-content) {
      padding: 10px !important;
    }
  }
}

.popover {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      border-radius: 16px !important;
      background: var(--trading-popover-background);
      border: 1px solid var(--trading-popover-border);
      border-top: 1.5px solid var(--trading-popover-border-top);
      box-shadow: 0 0 23px 15px rgba(59, 59, 59, 0.12);
    }
    :global(.ant-popover-arrow) {
      display: none;
    }
    :global(.ant-popover-inner-content) {
      padding: 10px !important;
    }
  }
}
