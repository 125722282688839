.toolTip {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.2);
      border-radius: var(--border-radius-small);
    }
    :global(.ant-popover-inner-content) {
      padding: var(--spacing-3xs) var(--spacing-small) !important;
    }
  }
}

.sidebarMenu {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      background-color: var(--color-gray-0);
      border-radius: 0px var(--border-radius-large) var(--border-radius-large) var(--border-radius-large) !important;
      box-shadow: 0px 9px 15px 0px rgba(59, 59, 59, 0.12);
      border: 1px solid var(--color-gray-200);
    }
    :global(.ant-popover-arrow) {
      display: none;
    }
    :global(.ant-popover-inner-content) {
      padding: var(--spacing-small) !important;
    }
  }
}

.popover {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 80%;
  max-height: calc(100% - 50px);
  :global(.ant-popover-content) {
    :global(.ant-popover-inner) {
      background-color: var(--color-gray-0);
      border-radius: 16px !important;
      box-shadow: 0px 9px 15px 0px rgba(59, 59, 59, 0.12);
      border: 1px solid var(--color-gray-200);
    }
    :global(.ant-popover-arrow) {
      display: none;
    }
    :global(.ant-popover-inner-content) {
      padding: var(--spacing-small) !important;
    }
  }
}

.transparent {
  position: absolute;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 80vw;
  max-height: calc(100vh - 80px);
  :global(.ant-popover-content) {
    max-width: 80vw;
    max-height: calc(100vh - 80px);
    :global(.ant-popover-inner) {
      border-radius: 16px !important;
      box-shadow: 0px 9px 15px 0px rgba(59, 59, 59, 0);
      border: 0px;
    }
    :global(.ant-popover-arrow) {
      display: none;
    }
    :global(.ant-popover-inner-content) {
      max-width: 80vw;
      max-height: calc(100vh - 80px);
      padding: 0px !important;
      position: relative;
      > div {
        max-width: 80vw;
        max-height: calc(100vh - 80px);
      }
    }
  }
}
